exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dzieki-bardzo-js": () => import("./../../../src/pages/dzieki-bardzo.js" /* webpackChunkName: "component---src-pages-dzieki-bardzo-js" */),
  "component---src-pages-dzieki-js": () => import("./../../../src/pages/dzieki.js" /* webpackChunkName: "component---src-pages-dzieki-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kursy-dostepnosc-cyfrowa-js": () => import("./../../../src/pages/kursy/dostepnosc-cyfrowa.js" /* webpackChunkName: "component---src-pages-kursy-dostepnosc-cyfrowa-js" */),
  "component---src-pages-kursy-feature-flags-js": () => import("./../../../src/pages/kursy/feature-flags.js" /* webpackChunkName: "component---src-pages-kursy-feature-flags-js" */),
  "component---src-pages-kursy-javascript-funkcje-js": () => import("./../../../src/pages/kursy/javascript-funkcje.js" /* webpackChunkName: "component---src-pages-kursy-javascript-funkcje-js" */),
  "component---src-pages-kursy-javascript-programowanie-funkcyjne-js": () => import("./../../../src/pages/kursy/javascript-programowanie-funkcyjne.js" /* webpackChunkName: "component---src-pages-kursy-javascript-programowanie-funkcyjne-js" */),
  "component---src-pages-kursy-programowanie-obiektowe-fundamenty-js": () => import("./../../../src/pages/kursy/programowanie-obiektowe-fundamenty.js" /* webpackChunkName: "component---src-pages-kursy-programowanie-obiektowe-fundamenty-js" */),
  "component---src-pages-kursy-tailwind-css-js": () => import("./../../../src/pages/kursy/tailwind-css.js" /* webpackChunkName: "component---src-pages-kursy-tailwind-css-js" */),
  "component---src-pages-kursy-testowanie-jednostkowe-wprowadzenie-js": () => import("./../../../src/pages/kursy/testowanie-jednostkowe-wprowadzenie.js" /* webpackChunkName: "component---src-pages-kursy-testowanie-jednostkowe-wprowadzenie-js" */)
}

